import React from 'react';
import Flicking from '@egjs/react-flicking';
import TreasureCardItem, { TreasureForCard } from '@/components/ckk/items/TreasureCardItem';
interface Props {
  treasures: TreasureForCard[];
  withoutStyle?: boolean;
}
function TreasureCardFlicking({
  treasures,
  withoutStyle
}: Props) {
  return <Flicking bound hideBeforeInit horizontal preventClickOnDrag align="prev" moveType="snap" data-sentry-element="Flicking" data-sentry-component="TreasureCardFlicking" data-sentry-source-file="TreasureCardFlicking.tsx">
      {treasures.map((treasure, index) => <TreasureCardItem key={treasure.name.en} isFirst={index === 0} isLast={index === treasures.length - 1} treasure={treasure} withoutStyle={withoutStyle} />)}
    </Flicking>;
}
export default TreasureCardFlicking;