import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { forwardRef } from 'react';
import CookieTypeItem from '@/components/ckk/items/CookieTypeItem';
import GradeItem from '@/components/ckk/items/GradeItem';
import PositionItem from '@/components/ckk/items/PositionItem';
import Img from '@/components/common/Img';
import { BaseCookie } from '@/types/ckk';
export type CookieForCard = Pick<BaseCookie, 'name' | 'grade' | 'position' | 'cookieType'> & {
  image: {
    base?: string;
  };
};
interface Props {
  cookie: CookieForCard;
  isFirst: boolean;
  isLast: boolean;
  withoutStyle?: boolean;
}
function CookieCardItem({
  cookie,
  isFirst,
  isLast,
  withoutStyle
}: Props, ref) {
  const {
    locale
  } = useRouter();
  return <div ref={ref} data-sentry-component="CookieCardItem" data-sentry-source-file="CookieCardItem.tsx">
      <Link key={cookie.name.en} className={`${cookie.grade}-header ${withoutStyle && isFirst ? '' : 'ml-4'} ${isLast ? 'mr-4' : '-mr-1'} flex rounded-lg bg-white py-3 pl-2 pr-5 shadow-lg`} href={{
      pathname: '/ckk/cookie/detail/[name]',
      query: {
        name: cookie.name.en
      }
    }} data-sentry-element="Link" data-sentry-source-file="CookieCardItem.tsx">
        <Img className="mr-2" src={cookie.image.base} style={{
        width: '100px',
        height: '100px',
        objectFit: 'contain'
      }} title={cookie.name[locale]} data-sentry-element="Img" data-sentry-source-file="CookieCardItem.tsx" />
        <div className="flex flex-col items-center">
          <GradeItem grade={cookie.grade} data-sentry-element="GradeItem" data-sentry-source-file="CookieCardItem.tsx" />
          <div className="ckkBold outlined mt-1 text-base">
            {cookie.name[locale]}
          </div>
          <div className="mt-2 flex w-full items-center justify-center gap-2">
            <div>
              {cookie.position && <PositionItem withText position={cookie.position} textClassName="ckkBold outlined !text-white" withLink={false} />}
            </div>
            <div>
              {cookie.cookieType && <CookieTypeItem withText cookieType={cookie.cookieType} textClassName="ckkBold outlined !text-white" withLink={false} />}
            </div>
          </div>
        </div>
      </Link>
    </div>;
}
export default forwardRef(CookieCardItem);