import { useRouter } from 'next/router';
import Img from '@/components/common/Img';
import { CKK_GRADE_MAP } from '@/constants/ckk/grade';
import { Grade } from '@/types/ckk';
interface Props {
  grade: Grade;
  className?: string;
}
function GradeItem({
  grade,
  className
}: Props) {
  const {
    locale
  } = useRouter();
  const {
    name,
    image
  } = CKK_GRADE_MAP[grade];
  const localeName = name[locale];
  return <Img alt={localeName} className={className} src={image} style={{
    verticalAlign: 'middle',
    width: 123,
    height: 23
  }} data-sentry-element="Img" data-sentry-component="GradeItem" data-sentry-source-file="GradeItem.tsx" />;
}
export default GradeItem;