import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { forwardRef } from 'react';
import GradeItem from '@/components/ckk/items/GradeItem';
import TreasureTypeItem from '@/components/ckk/items/TreasureTypeItem';
import Img from '@/components/common/Img';
import { Treasure } from '@/types/ckk';
export type TreasureForCard = Pick<Treasure, 'name' | 'grade' | 'type' | 'imageWithBackground'>;
interface Props {
  treasure: TreasureForCard;
  isFirst: boolean;
  isLast: boolean;
  withoutStyle?: boolean;
}
function TreasureCardItem({
  treasure,
  isFirst,
  isLast,
  withoutStyle
}: Props, ref) {
  const {
    locale
  } = useRouter();
  return <div ref={ref} data-sentry-component="TreasureCardItem" data-sentry-source-file="TreasureCardItem.tsx">
      <Link key={treasure.name.en} className={`${treasure.grade}-header ${withoutStyle && isFirst ? '' : 'ml-4'} ${isLast ? 'mr-4' : '-mr-1'} flex rounded-lg bg-white py-3 pl-2 pr-5 shadow-lg`} href={{
      pathname: '/ckk/treasure/detail/[name]',
      query: {
        name: treasure.name.en
      }
    }} data-sentry-element="Link" data-sentry-source-file="TreasureCardItem.tsx">
        <Img className="mr-2" src={treasure.imageWithBackground} style={{
        width: '100px',
        height: '100px',
        objectFit: 'contain'
      }} title={treasure.name[locale]} data-sentry-element="Img" data-sentry-source-file="TreasureCardItem.tsx" />
        <div className="flex flex-col items-center">
          <GradeItem grade={treasure.grade} data-sentry-element="GradeItem" data-sentry-source-file="TreasureCardItem.tsx" />
          <div className="ckkBold outlined mt-1 max-w-[10rem] text-center text-base">
            {treasure.name[locale]}
          </div>
          <div className="mt-1 flex w-full items-center justify-center gap-2">
            <TreasureTypeItem type={treasure.type} data-sentry-element="TreasureTypeItem" data-sentry-source-file="TreasureCardItem.tsx" />
          </div>
        </div>
      </Link>
    </div>;
}
export default forwardRef(TreasureCardItem);