import React from 'react';
import Flicking from '@egjs/react-flicking';
import CookieCardItem, { CookieForCard } from '@/components/ckk/items/CookieCardItem';
interface Props {
  className?: string;
  cookies: CookieForCard[];
  withoutStyle?: boolean;
}
function CookieCardFlicking({
  cookies,
  withoutStyle = false
}: Props) {
  return <Flicking bound hideBeforeInit horizontal preventClickOnDrag align="prev" moveType="snap" data-sentry-element="Flicking" data-sentry-component="CookieCardFlicking" data-sentry-source-file="CookieCardFlicking.tsx">
      {cookies.map((cookie, index) => <CookieCardItem key={cookie.name.en} cookie={cookie} isFirst={index === 0} isLast={index === cookies.length - 1} withoutStyle={withoutStyle} />)}
    </Flicking>;
}
export default CookieCardFlicking;